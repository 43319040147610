.fields li 
{
    display: flex;
    align-items: center;
    border: solid 2px #d0d0d0;
    border-radius: .2em;
    padding: .5em .8em .5em .5em;
    margin-bottom: 1em;
}

.fields p 
{
    max-width: none;
    font-weight: bold;
    margin: 0;
}

.fields-thumb
{
    overflow: hidden;
    flex-shrink: 0;
    width: 2em;
    height: 2em;
    background-color: #e8e8e8;
    padding: .5em;
    margin-right: .5em;
}

.fields-thumb img 
{
    display: block;
    width: 100%;
    height: auto;
}

.field-table
{
    table-Layout:'fixed' !important;
    width: '100%';
}

.field-table tr
{
    border: 1px solid transparent;
    border-bottom: 1px solid darkgray;
}

.field-table tr:last-child
{
    border: 1px solid transparent;
    border-bottom: 1px solid white;
}

.field-table tr:hover.moveable
{
    background-color: #E9EEF7;
}

.homeowner-tabs > a:not(.active) {
    background-color: var(--light);
    border-bottom: 1px solid #dee2e6;
}

.homeowner-tabs + .tab-content {
    margin-bottom: 1.75rem;
    box-shadow:0 0 0 15px white,0 0 0 16px #dee2e6;
}