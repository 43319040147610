.floatField {
  height: 45px !important;
  padding: 0.75rem 0.75rem 0 0.75rem !important;
  z-index: revert !important;
} /*::placeholder {
    opacity: 0;
}*/

.floatFieldSmall {
  height: 38px !important;
  padding: 0.75rem 0.75rem 0 0.75rem !important;
  z-index: revert !important;
}

.floatLabel {
  position: absolute;
  font-size: 0.8rem;
  top: 0.15rem;
  left: 0.5rem;
  color: #808080;
  font-weight: 500;
  background-color: inherit;
}

.floatFilled {
  position: absolute;
  font-size: 0.8rem;
  top: 0.15rem;
  left: 0.5rem;
  color: var(--primary);
  font-weight: 500;
  background-color: inherit;
}
