.IFrameLogo {
  position: absolute;
  left: 15px;
  bottom: 0px;
  z-index: 1000 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #6c757d;
  font-size: small;
}

.IFrameLogoLight {
  position: absolute;
  left: 15px;
  bottom: 0px;
  z-index: 1000 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f8f9fa;
  font-size: small;
}

.IFrameLogoSpan {
  font-weight: 900 !important;
}