.login-container {
  height: 100%;
  background-color: var(--primary);
  overflow: hidden;
}

#logo {
  position: absolute;
  top: 1rem;
  right: 0.25rem;
  width: 200px;
}

.title {
  font-size: 2em;
  font-weight: 600;
  color: white;
  margin: 0.5em 0;
}

.message {
  color: rgba(255, 255, 255, .7);
}

.title > img {
  margin: 0 0.5em 0 0;
}

.bad-login-alert {
  top: 2em;
  width: 80%;
  text-align: center;
  position: fixed !important;
  margin: 2.5em auto 0 auto;
  padding: 0.25em 2.25em !important;
}

.bad-login-alert > button {
  padding: 0.25rem 0.75rem!important;
}

.login-label {
  color: white;
}

.login-input {
  color: white !important;
  border-color: gray !important;
  background-color: var(--secondary) !important;
  filter: contrast(0.55) brightness(1.5);
}

.left-image {
  background-size: cover;
  padding: 0 !important;
  height: 100%;
  width: 100%;
  display: none;
}

.login-form, .forgot-form {
  margin: auto;
  width: 80%;
}

@media (min-width: 576px) {
  .left-image {
    display: inline-block;
  }
  .login-form, .forgot-form {
    width: 60%;
  }
  .bad-login-alert {
    width: 48%;
  }
}