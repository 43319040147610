.supportBanner {
  height: 200px;
  width: 100%;
  /*background-color: var(--secondary);*/
  /*background-image: url("/support-image.jpg");
    background-size: cover;*/
  text-align: center;
}

.supportBanner h1 {
  position: relative;
  top: 40%;
  font-family: 'Lato';
  font-weight: 900;
  color: #202020;
  /*text-shadow: 2px 2px #fff;*/
}
