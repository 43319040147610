.logo {
  height: 60px;
  position: relative;
}

.logo > img {
  object-fit: contain;
  max-height: 100%;
  position: absolute;
  bottom: 0;
}

.info {
  font-size: 0.85rem;
}
