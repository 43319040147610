.screen {
  position: absolute;
  width: 100%; /* calc(100% - var(--panel-width));*/
  left:0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #f4f5f8;
  padding: 0;
  overflow-y: auto;
  padding: 1rem 1rem 1rem 5rem;
}

#screen-title {
  font-size: 2.5vh;
  font-weight: 700;
}

@media (min-width: 576px) {
  #screen-title {
    font-size: 3.5vh;
  }
}

hr {
  color: #a8a8a8;
  background-color: #a8a8a8;
  width: 100%;
  height: 1px;
  border-color: #a8a8a8;
}

/*.hr {
    color: #a8a8a8;
    background-color: #a8a8a8;
    width: 100%;
    height: 1px;
    border-color: #a8a8a8;
}*/

.custom-file-input,
.custom-file-label {
  border: none!important;
}

.custom-file-label::after {
  border-radius: 0.25rem !important;
  border: 1px solid #ced4da !important;
  margin-right: 1rem;
}

.navPanel[data-nav-state=collapsed] + .screen {
  width: calc(100% - 55px);
}

.annotation {
  transform: translate3d(calc(30px), calc(-50%), 0);
  text-align: left;
  background: #181c20;
  color: #8c92a4;
  padding: 5px 10px;
  border-radius: 5px;
  -webkit-user-select: none;
  user-select: none;
  font-family: var(--leva-fonts-mono);
}

.line {
  height: 2px;
  background: #181c20;
  margin-bottom: -5px;
  margin-top: 3px;
}