.App {
  background-color: #f4f5f8;
  height: 100vh;
}

html,
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* :root {
  --panel-width: 200px;
} */

#root {
  /* Give app full page to work with */
  height: 100vh;
  /* font-family: sans-serif; */
}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.popover {
  max-width: '600px';
}

.progress {
  height: 35px;
  font-size: 20px;
}

.progress-bar {
  background-color: #77879f;
}

.snackbar {
  position: fixed;
  right: 2rem;
  bottom: 0;
  z-index: 99;
}

.btn svg {
  height: 20px;
  width: 20px;
}

.xl-text {
  font-size: 90px;
}

.pointer {
  cursor: pointer;
}

.border-bottom-thick {
  border: 0;
  border-bottom: 8px solid;
}

.fixed-bottom-right {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1030;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.opacity-75 {
  opacity: 0.75;
}

.rounded-top-0 {
  border-top-left-radius: 0!important;
  border-top-right-radius: 0;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0;
}

input[type="radio"], input[type="checkbox"] {
  filter: grayscale(1);
}

.btn-dark {
  background-color: black;
}

/* .btn-primary {
  background-color: #333F50
}

.btn-primary:hover {
  background-color: #212B39
} */

/* .btn.btn-primary[disabled] {
    background-color: #F4F5F8;
    border:none;
    color:#CFCFCF;
} */
/* 
.btn-secondary {
  background-color: transparent;
  border:solid 1px #333F50;
  color:#333F50;
}

.btn-secondary:hover {
  background-color: transparent;
  border:solid 1px #212B39;
  color:#212B39;
} */

.text-shadow {
  text-shadow: 1px 1px 2px black;
}
.scrollable-section {
  max-height: 500px;
  overflow-y: auto;
}

.accordion .card {
  overflow: inherit;
}

.pitch-dropdown .dropdown-menu {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  /* width: 50px !important; */
  min-width: 75px !important;
  height: 300px !important;
  overflow: auto;
}

.pitch-dropdown .dropdown-menu .dropdown-item {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.font-size-sm {
  font-size: 12px !important;
}

/*
* This section only applies if designer iframe is loaded 
*/

.font-vh {
  font-size: 0.6rem !important;
  background-color: #ffffff !important;
  max-width: 100%;
  margin: 0 !important;
  overflow-x: hidden;
  /* padding-left: 16px !important; */
  /* padding-right: 5px !important; */
}

.font-vh>.spinner,
.font-vh>.label {
  font-size: 1rem !important;
}

.font-vh input,
.font-vh .btn,
.font-vh .btn-group .btn,
.font-vh span,
.font-vh button>svg,
.font-vh .input-group select,
.font-vh .float_floatFilled__iOon3  {
  font-size: 0.6rem !important;
}

.font-vh img,
.font-vh button>svg {
  height: 18px !important;
  width: auto !important;
}

.font-vh .text-bold {
  font-size: 0.85rem !important;
}

.font-vh .col-4,
.font-vh .col-3,
.font-vh .col-1,
.font-vh .col-lg-4,
.font-vh .col-lg-8,
.font-vh .col {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.font-vh .col-lg-4 {
  margin-top: 2.25rem !important;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.modal-80w {
  width: 80%;
  max-width: none !important;
}

.modal-70w {
  width: 70%;
  max-width: none !important;
  margin-top: 150px;
}

.a4-land-size-fixed {
  /* max-height: 2100mm !important;
  min-height: 210mm !important; */
  max-width: 277mm !important;
  min-width: 277mm !important;
}

.a4-height {
  max-height:210mm !important;
  min-height: 210mm !important;
}

#shading-report .table th {
  padding: 0.6rem;
}

#shading-report .table td {
  padding: 0.6rem;
}

.table-monthly th,
.table-monthly td {
  padding: 0.12rem !important;
}

.page-break {
  break-before: always;
}

#myChart td {
  border: 1px solid white;
}

.dropdown-menu-default {
  min-width: 10.9rem !important;
}

.dropdown-menu-sm {
  min-width: 8.1rem !important;
}

#customer-portal-dropdown ~ .dropdown-menu {
  text-align: center;
  padding: 0 0 !important;
  min-width: 100% !important;
}

#customer-portal-dropdown ~ .dropdown-menu>.dropdown-item {
  text-align: center;
}

#customer-portal-dropdown ~ .dropdown-menu>.dropdown-divider {
  margin: 0;
}