.menu {
  padding: 0.25rem 0.5rem;
  border: 1px solid gray;
  border-radius: 1rem;
}

.open {
  background-color: var(--secondary);
  color: white;
  z-index: 1;
}

.menu img {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: top;
}

.menu > div > h4 {
  margin: 0;
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}
