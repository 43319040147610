
.overlay {
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.toolbar3d {
  background-color: '#ffffff';
  color: 'black';
}

.selectBox {
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.1);
  position: fixed;
}

.container3d {
  height: 100%;
  visibility: visible;
}

.container3d-invisible {
  height: 1px;
  visibility: collapse;
}