.card-hover {
    box-shadow: 0 1.4px 1.7px rgba(0, 0, 0, 0.017), 0 3.3px 4px rgba(0, 0, 0, 0.024), 0 6.3px 7.5px rgba(0, 0, 0, 0.03), 0 11.2px 13.4px rgba(0, 0, 0, 0.036), 0 20.9px 25.1px rgba(0, 0, 0, 0.043), 0 50px 60px rgba(0, 0, 0, 0.06);
    cursor: pointer !important;
}

.card-hover-selected {
    border: 6px solid #000;
}

.vintage:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(72, 76, 97, 0) 0%, rgba(11, 11, 11, 0.25) 75%);
    z-index: 2;
    bottom: 0;
    left: 0;
}

.vintage:hover:before {
    bottom: 0;
    display: none;
}

.capture-date {
    z-index: 99 !important;
    position: relative;
    font-size: 0.65rem;
}