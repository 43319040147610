@use "sass:color";

$theme-colors: (
    "primary": #050505,
    "secondary": #7F7F7F,
    "light": #F4F5F8,
    // variations
    // TODO: automatically calculate these from the base color, and selected level
    "primary-100": #f5f6f7,
    "primary-200": #f5f6f7,
    "primary-300": #f5f6f7,
    "primary-400": #f5f6f7,
    "primary-500": #f5f6f7,
    "primary-600": #050505,
    "primary-700": #050505,
    "primary-800": #050505,
    "primary-900": #050505,
);

$breadcrumb-divider: quote("›");

.breadcrumb-item {
    color: var(--primary);
    font-weight: 800;
    > a, a:hover {
        color: var(--secondary);
        font-weight: normal;
    };
}

/* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.rangeslider__fill { background-color: var(--primary)!important; }

@import "~bootstrap/scss/bootstrap";

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @each $prop, $abbrev in (width: w, height: h) {
            @each $size, $length in $sizes {
            .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
            }
        }
    }
}