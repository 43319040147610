@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}

.spinner {
  z-index: 1000 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 180px;
  height: 180px;
  background-color: var(--secondary);
  border-radius: 50%;
  padding: 1.5rem 1rem;
  margin: 0 0;
  animation: spin 2s linear infinite;
}

.label {
  z-index: 1000 !important;
  position: absolute;
  top: 50%;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  transform: translate(0%, -50%);
  font-size: 2vw;
  color: #efefef!important;
  background-color: #002a3f86;
}