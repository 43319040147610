.navPanel {
  position: absolute;
  width: 200px;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  background-color: var(--primary);
  z-index: 12;
}

.logo {
  margin-top: 120px;
  padding: 0 10%;
  text-align: center;
  font-size: 2vw !important;
}

.navItem {
  display: block!important;
  flex-wrap: nowrap!important;
  margin: 0!important;
  padding: 0.75rem 0rem;
  // overflow-x: hidden;
  & a {
    margin-left: 0.5rem;
    width: 100%!important;
    color: white !important;
    :hover {
      text-decoration: none;
    }
  }
  & span {
    margin-left: 0.25 rem;
  }
  & button {
    padding: 0;
    margin-left: 0.5rem;
    color: white;
    background-color: inherit;
    border: none;
  } :focus {
    outline: none;
  }
}

.navSubMenu {
  position: relative;
  background-color: var(--primary);
  // filter: brightness(1.25);
  margin-top: 0.75rem;
  & .subMenuItem {
    margin-left: 1.5rem;
    padding: 0.5rem 0;
    & a {
      color: white!important;
    }
  }
}

.collapseToggle {
  border-top: 1px solid var(--secondary) !important;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.navPanel[data-nav-state=collapsed] {
  width: 55px;
 & .logo img {
    display: none;
  }
  & .navItem span {
    display: none;
  }
  & .navSubMenu {
      display: none;
  }
  & .navItem:hover {
    background-color: var(--primary);
    filter: brightness(1.25);
    width: 220px;
    & span {
      margin-left: 1rem;
      display: inline;
    }
    & .navSubMenu {
      position: absolute;
      display: inline;
      left: 55px;
      top: 48.5px;
      margin-top: 0;
      width: 165px;
      border-top: 1px solid #272727 !important;
      filter: brightness(1);
      & .subMenuItem {
        margin-left: 0rem;
      }
    }
  }
  .collapseToggle span {
    display: none;
  }
}

.navPanel[data-nav-state=full] .navSubMenu[data-menu-state=close] {
  display: none;
}