.body {
  position: absolute;
  width: 100%;
  height: 450px;
}

.cropper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100% - 75px);
  width: 100%;
}

.controls {
  position: relative;
  top: 88%;
  bottom: 0;
  left: 50%;
  width: 50% !important;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}
